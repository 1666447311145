import React, { useEffect, useState } from "react"
import "../assets/css/404.scss"
import SEO from "../components/layout/seo"

const story = [
  { old: "kpbEGW1645134337", new: "DwPn671655974338" },
  { old: "TguXhE1645134338", new: "OgSvMP1679400550" },
  { old: "ZIV9mp1645134338", new: "d1MlYG1681482779" },
  { old: "KLUTjo1645134338", new: "" },
  { old: "xcVUXv1645134338", new: "" },
  { old: "rLdswh1645134338", new: "" },
  { old: "UwtdKj1645134338", new: "" },
  { old: "WcSAWA1645134338", new: "" },
  { old: "DMdsmI1645134338", new: "" },
  { old: "6KCEYe1645134338", new: "" },
  { old: "2nphD11645134338", new: "" },
  { old: "QMMKR51645134338", new: "" },
  { old: "HPlaS01645134338", new: "" },
  { old: "MYYVLv1645134338", new: "" },
  { old: "Wh8IJj1645134338", new: "" },
  { old: "GvAy8y1645134338", new: "" },
  { old: "tXDCPO1645134338", new: "" },
  { old: "S7gToR1645134338", new: "" },
  { old: "jTWF4W1645134338", new: "" },
  { old: "ey8jRf1645134338", new: "" },
  { old: "HE3u1e1645134338", new: "" },
  { old: "jZq4P11645134339", new: "" },
  { old: "sxKrIL1645134339", new: "" },
  { old: "3Lb2TH1645134339", new: "" },
  { old: "bXR5Ds1645134339", new: "" },
  { old: "o6dxsj1645134339", new: "" },
  { old: "XpQR9O1645134339", new: "" },
  { old: "Ktlleh1645134339", new: "" },
  { old: "UGXsNI1645134339", new: "" },
  { old: "6PaHsm1645134339", new: "" },
]

const NotFoundPage = () => {
  let [newUrl, setNewUrl] = useState(null)

  useEffect(() => {
    let href = window.location.href

    // Remove trailing slash from URL
    if (href.endsWith("/")) {
      href = href.slice(0, -1)
    }

    let qrCode = href.split("/")
    let url = story.find(items => {
      return items.old === qrCode.at(-1)
    })
    setNewUrl(url?.new || "")
  })

  return newUrl === null ? (
    <>
      <SEO title="Loading..." />
      <div className="notfound-page">
        <div>
          <h2 className="mt-5 text-center">Loading...</h2>
        </div>
      </div>
    </>
  ) : newUrl === "" ? (
    <>
      <SEO title="QR is working but not yet activated" />
      <div className="notfound-page">
        <div>
          <h2 className="mt-5 text-center">
            QR is working but not yet activated.
          </h2>
        </div>
      </div>
    </>
  ) : (
    <>
      {window.location.assign(`${process.env.EXP_URL}/story/${newUrl}`)}
      <SEO title="You will be redirected to the coffee story" />
      <div className="notfound-page">
        <div>
          <h2 className="mt-5 text-center">
            You will be redirected to the coffee story shortly.
          </h2>
          <p className="mt-3 text-center">
            <a href={`${process.env.EXP_URL}/story/${newUrl}`}>Click here</a> if
            you are not redirected automatically.
          </p>
        </div>
      </div>
    </>
  )
}

export default NotFoundPage
